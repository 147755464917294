

export default{
    overview: 'Επισκόπηση πελάτη',
    back:'Επιστροφή στα διατροφικά πλάνα του πελάτη',
    tabs: {
        base: {
            title: 'Στοιχεία',
            subtitle: 'Βασικές πληροφορίες επικοινωνίας'
        },
        progress: {
            title: 'Φύλλο παρακολούθησης',
            subtitle: 'Καταγραφή της προόδου ανά ραντεβού'
        },
        dietPlans: {
            title: 'Διατροφικά Πλάνα',
            subtitle: 'Εβδομαδιαία προγράμματα διατροφής',
        },
        measures: {
            title: 'Μετρήσεις',
            subtitle: 'Σωματομετρικές, βιταμίνες, αιματολογικές...'
        },
        sports: {
            title: 'Άσκηση & Συμπληρώματα',
            subtitle: 'Άσκηση και συνιστώμενα συμπληρώματα'
        },
        questionnaire: {
            title: 'Ερωτηματολόγιο',
            subtitle: 'Σύντομο καθώς και αναλυτικό ερωτηματολόγιο'
        },
        mobile: {
            title: 'Mobile Εφαρμογή',
            subtitle: 'Ημερολόγιο καταγραφής - στόχοι'
        },
        files: {
            title: 'Αρχεία',
            subtitle: 'Λίστα αποθηκευμένων αρχείων πελάτη'
        },
    },
    age: 'Ηλικία',
    year:'έτη',
    years:'ετών',
    appointmentHistory: 'Ιστορικότητα ραντεβού',
    delete: 'Διαγραφή πελάτη',
    disconnectApp: 'Πρέπει πρώτα να αποσυνδέσετε το mobile app.',
    goals: {
        title: '',
        completed: 'Εκπληρωμένοι στόχοι',
        nonCompleted: 'Ανεκπλήρωτοι στόχοι',
        goal: 'Στόχος',
        goals: 'Στόχοι',
        goalPlaceholder: 'Προσθήκη νέου στόχου',
        targetDate: 'Εκπλήρωση στόχου έως',
        completionDate: 'Ημερομηνία ολοκλήρωσης'
    },
    events: {
        future: 'Μελλοντικά',
        past: 'Ολοκληρωμένα',
        cancelled: 'ακυρωμένο',
        approved: 'επιβεβαιωμένο'
    },
    absolutePercentage: 'Απόλυτη μεταβολή',
    percentage: 'Ποσοστιαία μεταβολή',
    weightChange: 'Μεταβολή βάρους',
    fatChange: 'Μεταβολή λίπους',
    fromFirst: '(από την 1η συνεδρία)',
    addProgressItem: 'Προσθήκη εγγραφής',
    editProgressItem: 'Επεξεργασία εγγραφής',
    invalidData: 'Παρακαλώ εισάγετε όλα τα υποχρεωτικά στοιχεία για να δημιουργήσετε τον πελάτη.',
    shareMeasurements: 'Κοινοποίηση μετρήσεων σε mobile εφαρμογή'
}